<template>
  <div>
   <div v-if="currentwidth<700">
     <div class="bg1 bgsize2">
       <div class="padd30 colw">
         <div class="paddzy10">
           <div class="ftleft wid36px1 bgwhite mcenter">
             <van-image :src="require('@/assets/jxfw/logo.png')" round width="20" height="20" class="mt5" />
           </div>
           <div class="ml10 font20 ftleft mr10">建信服务</div>
           <div class="ml20 font14 ftleft mt3" @click="toHome">首页</div>
           <div class="ftright mt3 font14" @click="handleLeft">小程序登录</div>
           <div class="clear"></div>
         </div>
         <div class="bgwhite w150px mcenter" style="margin-top: 130px;margin-left: 30px" @click="toOpen">
           <van-image :src="require('@/assets/img/jxfw.png')" width="118" height="118" class="mt20" />
         </div>
       </div>
     </div>
    </div>
    <div v-else>
      <div class="bg bgsize1">
        <div class="w70 padd20 colw">
          <div class="padd30">
            <div class="ftleft wid36px bgwhite mcenter">
              <van-image :src="require('@/assets/jxfw/logo.png')" round width="26" height="26" class="mt5" />
            </div>
            <div class="ml10 font22 ftleft">建信服务</div>
            <div class="ml50 font15 ftleft mt3" @click="toHome">首页</div>
            <div class="ftright mt3 font15" @click="handleLeft">小程序登录</div>
            <div class="clear"></div>
          </div>
          <!--          <div class="mt60 paddtop20 font36 ml50">建信服务</div>
                    <div class="mt20 font20 ml50">开启投标信息化AI新时代</div>
                    <div class="bgwhite w178px mcenter mt50 ml50">
                      <van-image :src="require('@/assets/img/jxfw.png')" width="138" height="138" class="mt20" />
                    </div>-->
        </div>
      </div>
    </div>
    <div v-for="(it,ke) in types" :key="ke" :class="[ke != 1 ? 'bgee':'bgwhite']" class="paddtop30 mcenter">
      <div class="font20 mt30">{{it.title}}</div>
      <div class="mt20 font14 col6">{{it.content}}</div>
      <div style="position: relative;" class="mt50">
        <div v-if="currentwidth < 410">
          <van-image :src="require('@/assets/jxfw/'+it.img1+'.png')" :height="250"/>
          <div style="position: absolute;top: 30px;left: 55%">
            <van-image :src="require('@/assets/jxfw/'+it.img2+'.png')" :width="ke==0?180:170" />
          </div>
          <div style="position: absolute;top: 55%;right: 57%;">
            <van-image :src="require('@/assets/jxfw/'+it.img3+'.png')" :width="ke==0?170:150"/>
          </div>
        </div>
        <div v-else-if="currentwidth < 720">
          <van-image :src="require('@/assets/jxfw/'+it.img1+'.png')" :height="380"/>
          <div style="position: absolute;top: 30px;left: 55%">
            <van-image :src="require('@/assets/jxfw/'+it.img2+'.png')" :width="ke==0?240:200" />
          </div>
          <div style="position: absolute;top: 55%;right: 57%;">
            <van-image :src="require('@/assets/jxfw/'+it.img3+'.png')" :width="ke==0?230:200"/>
          </div>
        </div>
        <div v-else-if="currentwidth < 1100">
          <van-image :src="require('@/assets/jxfw/'+it.img1+'.png')" :height="500"/>
          <div style="position: absolute;top: 30px;left: 57%">
            <van-image :src="require('@/assets/jxfw/'+it.img2+'.png')" :width="ke==0?370:340" />
          </div>
          <div style="position: absolute;top: 55%;right: 57%;">
            <van-image :src="require('@/assets/jxfw/'+it.img3+'.png')" :width="ke==0?350:320"/>
          </div>
        </div>
        <div v-else>
          <van-image :src="require('@/assets/jxfw/'+it.img1+'.png')" :height="560"/>
          <div style="position: absolute;top: 30px;left: 58%">
            <van-image :src="require('@/assets/jxfw/'+it.img2+'.png')" :width="ke==0?450:(ke == 1?350:400)" />
          </div>
          <div style="position: absolute;top: 55%;right: 58%;">
            <van-image :src="require('@/assets/jxfw/'+it.img3+'.png')" :width="ke==0?430:400"/>
          </div>
        </div>

      </div>
    </div>
    <div class="mcenter mt60 paddzy10" id="jxfw">
      <div class="font36">微信扫一扫，你关心的都在这里</div>
      <div class="mt30 padd30" @click="toOpen"><van-image :src="require('@/assets/jxfw/img.png')" class="mt30"/></div>
    </div>
    <div>
      <new-bottom></new-bottom>
    </div>
  </div>
</template>

<script>
import newBottom from "@/components/newBottom.vue"
import qs from "qs";
import axios from "axios";
import {Toast} from "vant";
export default {
  name: "jxfwDetail",
  components: {newBottom},
  data(){
    return{
      types: [
        {title: "深度解析招标文件",content: "招标文件内容一键解析，招标文件、图纸、清单免费下载",img1: "img1",img2: "img11",img3: "img12"},
        {title: "标讯订阅",content: "多维度订阅，灵活定制，精准推送",img1: "img2",img2: "img21",img3: "img22"},
        {title: "大数据分析 找项目 查公司",content: "查项目，查企业，查代理，查业主，大数据分析，找同类项目，寻找历史投标企业",img1: "img3",img2: "img31",img3: "img32"},
      ],
      currentwidth: 1000,
      userAgent: "",
      urllink: "",
    }
  },
  mounted() {
    let that = this;
    that.currentwidth = window.document.body.offsetWidth;
    window.onresize = () => {
      that.currentwidth = window.document.body.offsetWidth;
    }
  },
  methods: {
    toHome(){
      this.$router.push({path: "/"})
    },
    toOpen(){
        let that = this;
        let userAgent = navigator.userAgent;
        let isAndroid = /Android/i.test(userAgent);
        let isiOS = /(iPhone|iPad|iPod|iOS)/i.test(userAgent);
        that.userAgent = userAgent;
        //console.log("设备",userAgent)
        if (isAndroid || isiOS){
          if (that.urllink){
            window.location.href = that.urllink;
          }else {
            that.getlink()
          }
        }
    },
    getlink(){
      let id = "";
      let data = {
        code: "",
        p: "",
      }
      //window.location.href = "https://sixu.work/jxfw?c=lidongyang&p=sj"
      if (window.location.href.indexOf("c=") != -1){
        id = qs.parse(window.location.href.split("#")[0].split("?")[1]).c;
        data.code = id;
        let path = qs.parse(window.location.href.split("#")[0].split("?")[1]).p;
        if (path == "sj"){
          data.p = "pages/program/index/main"
        }
      }
      //console.log("1234",data)
      let url = "https://jz.4lambs.com:443/jxfw/share/sellurl";
      axios.post(url,data).then(res => {
        if (res != null && res.data != null && res.data.content != null){
          this.urllink = res.data.content;
          //console.log("link---",this.urllink)
          window.location.href = res.data.content
        }else {
          Toast("获取失败")
        }
      })
    },
    handleLeft(index) {
      //this.navgatorIndex = index;

      this.$el.querySelector(`#jxfw`).scrollIntoView({
        behavior: "smooth",  // 平滑过渡
        block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
      //this.listBoxState=false;

      /*let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },200);*/
    },
  }
}
</script>

<style scoped>
.bg1{background-image: url("../../assets/img/jxtop3.png");background-repeat: no-repeat;height: 400px;width: 100%;}
.bg{background-image: url("../../assets/img/jxtop1.png");background-repeat: no-repeat;height: 596px;width: 100%;}
.bgsize1{background-size: cover;}
.bgsize2{background-size: cover}
.wid36px{width: 36px;height: 36px;border-radius: 50%;}
.wid36px1{width: 30px;height: 30px;border-radius: 50%;}
.w178px{width: 178px;height: 178px;border-radius: 50%;}
.w150px{width: 150px;height: 150px;border-radius: 50%;}
.bgee{background: #EEF8FF;}
</style>